import { h } from 'preact'
import Logo from '../components/logo'

const Header = () => (
  <header class='header'>
    <nav class='navbar navbar-expand-lg navbar-light bg-white shadow'>
      <div class='container'>
        <a href='/' class='navbar-brand py-1' title='Home'>
          <Logo />
        </a>

        <button type='button' data-toggle='collapse' data-target='#navbarCollapse' aria-controls='navbarCollapse' aria-expanded='false' aria-label='Toggle navigation' class='navbar-toggler navbar-toggler-right'>
          <span class='navbar-toggler-icon' />
        </button>

        <div id='navbarCollapse' class='collapse navbar-collapse'>
          <ul class='navbar-nav ml-auto'>
            <li class='nav-item'>
              <a href='/' class='nav-link'>Home</a>
            </li>
            <li class='nav-item'>
              <a href='/search' class='nav-link'>Search Rentals</a>
            </li>
            <li class='nav-item'>
              <a href='/events' class='nav-link'>Events</a>
            </li>
            <li class='nav-item'>
              <a href='/about-us' class='nav-link'>About Us</a>
            </li>
            <li class='nav-item'>
              <a href='https://app.janiis.com/#/owner-portal/login' rel='noopener noreferrer' target='_blank' class='nav-link'>Owner Portal</a>
            </li>
            <li class='nav-item mt-3 mt-md-0 ml-md-3 d-inline-block'>
              <a href='https://blogs.reservationsunlimited.com/live-webcam/' rel='noopener noreferrer' target='_blank' class='btn btn-primary'>Live Cam</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
)

export default Header
